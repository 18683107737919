import React, { useState, useEffect } from "react";
import "../StyleSheets/zongworld.css";
import Logo from "../Assets/img/zong-world/mediq_logo_1.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import "swiper/css/bundle";
import Card1 from "../Assets/img/zong-world/card1.webp";
import Arrow from "../Assets/img/zong-world/book-now-icon.svg";
import Card2 from "../Assets/img/zong-world/card2.webp";
import Card3 from "../Assets/img/zong-world/card3.webp";
import Card4 from "../Assets/img/zong-world/card4.webp";
import Icon1 from "../Assets/img/zong-world/icon1.webp";
import Icon2 from "../Assets/img/zong-world/icon2.webp";
import Icon3 from "../Assets/img/zong-world/icon3.webp";
import Icon4 from "../Assets/img/zong-world/icon4.webp";
import Icon5 from "../Assets/img/zong-world/icon5.webp";
import Icon6 from "../Assets/img/zong-world/icon6.webp";
import Icon7 from "../Assets/img/zong-world/icon7.webp";
import Info1 from "../Assets/img/zong-world/info1.webp";
import Info2 from "../Assets/img/zong-world/info2.webp";
import Info3 from "../Assets/img/zong-world/info3.webp";
import Info4 from "../Assets/img/zong-world/info4.webp";
import Youtube from "../Assets/img/zong-world/Frame.svg";
import lifesehtIcon from "../Assets/img/zong-world/life-seht-icon.webp";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Diet1 from "../Assets/img/zong-world/diet1.webp";
import Diet2 from "../Assets/img/zong-world/diet2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

type Props = {
  validateSubscribe: (_packageName: string) => Promise<void>;

  mainLoader: boolean;
  isLoader: boolean;
  onModalPress: () => void;
  showModal: boolean;
  userSubscribed: boolean;
  errorMsg: string;
  onUnSubscribePress: () => Promise<void>;
  jazzEncryptedMsisdn: string;
};
const ZongWorld = (props: Props) => {
  console.log("getting encoded jazz number", props.jazzEncryptedMsisdn);
  return (
    <>
      <div className="jazzworld-wraper">
        <div className="header">
          <div className="container">
            <div className="main-header">
              <div className="logo">
                <img src={Logo} alt="mediq-logo" />
              </div>

              <div className="buttons">
                {props.userSubscribed ? (
                  <>
                    {props.isLoader ? (
                      <a className="buttons">UnSubscribing...</a>
                    ) : (
                      <a className="buttons" onClick={props.onUnSubscribePress}>
                        UnSubscribe
                      </a>
                    )}
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.homemedics.app&hl=en"
                    >
                      Get App!
                    </a>
                  </>
                ) : (
                  <>
                    {props.isLoader ? (
                      <a className="buttons">Subscribing...</a>
                    ) : (
                      <>
                        <a href="#lifesehat"> Subscribe</a>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.homemedics.app&hl=en"
                        >
                          Get App!
                        </a>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="discounted-swiper jw-main-padding">
          <div className="container">
            <div className="discounted-swiper-wraper">
              <Swiper
                slidesPerView={2.3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.2,
                  },
                  1024: {
                    slidesPerView: 2.3,
                  },
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="discounted-swiper-card pink-card">
                    <div className="discounted-text">
                      <p>
                        Get <span>Free</span>
                      </p>
                      <h3>
                        Doctor Consultation <br />
                        Today!
                      </h3>
                      <div className="book-now pink-btn">
                        {props.userSubscribed ? (
                          <a
                            href={`https://app.mediq.com.pk/doctor-consultation?phone_number=${props.jazzEncryptedMsisdn}&network=zong`}
                          >
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        ) : (
                          <a href="#lifesehat">
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        )}
                      </div>
                    </div>
                    <img src={Card1} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-swiper-card green-card">
                    <div className="discounted-text">
                      <p>
                        <span> BIG 50%</span> Off
                      </p>
                      <h3>
                        On Specialist
                        <br /> Consultations
                      </h3>
                      <div className="book-now green-btn">
                        {props.userSubscribed ? (
                          <a
                            href={`https://app.mediq.com.pk/doctor-consultation?phone_number=${props.jazzEncryptedMsisdn}&network=zong`}
                          >
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        ) : (
                          <a href="#lifesehat">
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        )}
                      </div>
                    </div>
                    <img src={Card2} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-swiper-card red-card">
                    <div className="discounted-text">
                      <p>
                        <span className="twentydisc"> 20%</span> Disc.
                      </p>
                      <h3>On Home Lab Tests</h3>
                      <div className="book-now red-btn">
                        {props.userSubscribed ? (
                          <a
                            href={`https://app.mediq.com.pk/doctor-consultation?phone_number=${props.jazzEncryptedMsisdn}&network=zong`}
                          >
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        ) : (
                          <a href="#lifesehat">
                            Book Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        )}
                      </div>
                    </div>
                    <img src={Card3} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-swiper-card door-step">
                    <div className="discounted-text">
                      <p>
                        Get medicine <br /> delivered to your
                      </p>
                      <h3>Door Step</h3>
                      <div className="book-now door-btn">
                        {props.userSubscribed ? (
                          <a
                            href={`https://app.mediq.com.pk/pharmacy?phone_number=${props.jazzEncryptedMsisdn}&network=zong`}
                          >
                            Order Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        ) : (
                          <a href="#lifesehat">
                            Order Now
                            <img src={Arrow} alt="arrow" />
                          </a>
                        )}
                      </div>
                    </div>
                    <img src={Card4} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="available-packages">
          <div className="container">
            <div className="">
              <h2 className="main-heading">Available Package</h2>
            </div>
            <div className="swiper-slider section-inner-margin">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="discounted-packages blue-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        50% <span>Off</span>
                      </div>
                      <p>
                        On Video
                        <br /> Consultation
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages pink-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        50% <span>Off</span>
                      </div>
                      <p>
                        On Message <br /> Consultation
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages green-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        20% <span>Off</span>
                      </div>
                      <p>
                        On Laboratory <br /> Service
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages purple-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        08% <span>Off</span>
                      </div>
                      <p>
                        On Pharmacy <br /> Service
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* new section  seht and life tahafuzz*/}
        <div className="life-seht-section" id="lifesehat">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="life-seht-card">
                  <div className="life-seht-heading-parent">
                    <div className="life-seht-heading">Health (Sehat)</div>
                    <span>5+ Tax</span>
                  </div>
                  <ul>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Tele/video consultation with general practitioners 24/7
                      (Free Unlimited)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Tele / video consultation with Specialists (32
                      Specialties) (50% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Online lab services (20% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Online pharmacy services (8% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Electronic Medical Record
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Video on Demand
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Health Tips
                    </li>
                  </ul>

                  <div
                    className="subscribe-package-btn"
                    onClick={() => props.validateSubscribe("ms")}
                    id="subscribe-plan"
                  >
                    {props.userSubscribed ? (
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.homemedics.app&hl=en"
                      >
                        Get App!
                      </a>
                    ) : (
                      <>
                        {props.isLoader ? (
                          <div className="subscribe-package-btn">
                            <button className="subscribe-package-btn">
                              Subscribing...
                            </button>
                          </div>
                        ) : (
                          <>
                            <button>Subscribe Package</button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="life-seht-card last-child">
                  <div className="life-seht-heading-parent">
                    <div className="life-seht-heading">Life (Tahafuzz)</div>
                    <span className="orange">8+ Tax</span>
                  </div>
                  <ul>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Tele/video consultation with general practitioners 24/7
                      (Free Unlimited)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Tele / video consultation with Specialists (32
                      Specialties) (50% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Online lab services (20% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Online pharmacy services (8% Discount)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Electronic Medical Record
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Video on Demand
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Health Tips
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Hospitalization cover (PKR 2,000 per night up to 30 nights
                      in a year)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Accidental coverage (PKR 50,000)
                    </li>
                    <li>
                      <img src={lifesehtIcon} alt="icon" />
                      Life Insurance (PKR 250,000)
                    </li>
                  </ul>
                  <div
                    className="subscribe-package-btn"
                    onClick={() => props.validateSubscribe("ms")}
                  >
                    {props.userSubscribed ? (
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.homemedics.app&hl=en"
                      >
                        Get App!
                      </a>
                    ) : (
                      <>
                        {props.isLoader ? (
                          <div className="subscribe-package-btn">
                            <button className="subscribe-package-btn">
                              Subscribing...
                            </button>
                          </div>
                        ) : (
                          <>
                            <button>Subscribe Package</button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* new section  seht and life tahafuzz*/}
        <div className="feature-service jw-main-padding">
          <div className="container">
            <div className="feature-text">
              <h3 className="main-heading">Feature Services</h3>
            </div>
            <div className="swiper-services section-inner-margin">
              <Swiper
                slidesPerView={6}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                }}
                className="mySwiper service"
              >
                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon1} alt="health" />
                    </div>
                    <h3>General Practitioners</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon2} alt="health" />
                    </div>

                    <h3>Specialist Doctors</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon3} alt="health" />
                    </div>
                    <h3>Paperless Claims</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon4} alt="health" />
                    </div>
                    <h3>Video on Demand</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon5} alt="health" />
                    </div>
                    <h3>Health Tips</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon6} alt="health" />
                    </div>
                    <h3>Lab Services</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon7} alt="health" />
                    </div>
                    <h3>Pharmacy Services</h3>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="information">
          <div className="container">
            <div className="">
              <h4 className="main-heading">Authentic Health Information</h4>
            </div>

            <div className="slider-info  section-inner-margin">
              <Swiper
                slidesPerView={3.3}
                spaceBetween={30}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                  // clickable: true,
                }}
                pagination={false}
                modules={[FreeMode, Navigation, Pagination]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3.3,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info1} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        Effective Exercises and Tips with Dr. Burhan Rabi Today
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info2} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        Top 10 Signs and Symptoms Explained by Miss. Anum Khalid
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info3} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>Impact on Mothers & Baby Defeating Anemia</p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info4} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        How to Fade Discolored Spots and Achieve Clearer Skin
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-btn-flex">
                <div className="swiper-button-prev" style={{ color: "#fff" }}>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.20942 12.2056L0.106636 6.10278L6.20942 0L7.20615 0.996729L2.78207 5.40332H13.886V6.80224H2.78207L7.20615 11.2088L6.20942 12.2056Z" />
                  </svg>
                </div>
                <div className="show-all-button">
                  <a target="_blank" href=" https://mediq.com.pk/video/">
                    Show All
                  </a>
                </div>
                <div className="swiper-button-next" style={{ color: "#fff" }}>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.79058 12.2056L13.8934 6.10278L7.79058 0L6.79385 0.996729L11.2179 5.40332H0.114014V6.80224H11.2179L6.79385 11.2088L7.79058 12.2056Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="health-tips jw-main-padding">
          <div className="container">
            <div className="health-text">
              <h2 className="main-heading">Health Tips</h2>
              <a
                target="_blank"
                href="https://mediq.com.pk/mediq-healthcareblogs/"
              >
                View All
              </a>
            </div>

            <div className="slider-health section-inner-margin">
              <Swiper
                slidesPerView={2.2}
                spaceBetween={30}
                navigation={false}
                pagination={true}
                modules={[FreeMode, Navigation, Pagination]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="main-diet bg-red">
                    <div className="slider-text">
                      <p>
                        No Dieting, Do this One Step for Weight Loss and
                        Wellness
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/5-mental-health-benefits-of-athletics/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet1} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="main-diet bg-yellow">
                    <div className="slider-text">
                      <p>
                        Attention Deficit Hypersensitivity Disorder – Overview
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/attention-deficit-hypersensitivity-disorder-overview/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet2} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="main-diet bg-blue">
                    <div className="slider-text">
                      <p>
                        <p>
                          Mental Health and <br /> Well-Being
                        </p>
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/mental-health-and-well-being/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet1} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        {/* <footer className="footer zong-world-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="footer-link">
                  <div className="foo-heading">Social</div>

                  <div className="social-link">
                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/c/mediqsmarthealthcare"
                        className="f-link"
                      >
                        <span> Youtube</span>{" "}
                        <FontAwesomeIcon icon={faYoutube} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/mediq_smart_healthcare/"
                        className="f-link"
                      >
                        {" "}
                        <span>Instagram</span>{" "}
                        <FontAwesomeIcon icon={faInstagram} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://twitter.com/ShcMediq"
                        className="f-link"
                      >
                        {" "}
                        <span> X</span> <FontAwesomeIcon icon={faXTwitter} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://web.facebook.com/mediq.smarthealthcare"
                        className="f-link"
                      >
                        {" "}
                        <span> Facebook</span>{" "}
                        <FontAwesomeIcon icon={faFacebook} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="footer-link">
                  <div className="foo-heading">Contact Us</div>

                  <div className="contact-text">
                    <p>UAN: 051 111 377 377 </p>
                    <p>Phone: 051 877 3377 </p>
                    <p>
                      Web:{" "}
                      <a target="_blank" href="#" className="mediqhref">
                        www.mediq.com.pk
                      </a>
                    </p>
                  </div>
                  <div className="footer-text">
                    © 2024. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export default ZongWorld;
